import axios from "axios";

export class ImageService {
    private bearerToken: string = '';
    private serverUrl: string;
    constructor(serverRoot: string) {
        this.serverUrl = serverRoot + "api/avatars/";
    }
    public setBearerToken(token: string) {
        this.bearerToken = token;
    }

    private async postFile(path: string, data: any) {
        let config = {
            headers: { 'Authorization': "bearer " + this.bearerToken, 'Content-Type': `multipart/form-data; boundary=${data._boundary}` }
        };
        return axios.post(this.serverUrl + path, data, config).then(res => {
            return res.data;
        }).catch(error => {
            console.log(error);
        })
    }
    private async put(path: string, data: any) {
        let config = {
            headers: { 'Authorization': "bearer " + this.bearerToken }
        };
        return axios.put(this.serverUrl + path, data, config).then(res => {
            return res.data;
        })
    }

    async uploadImage(formData: FormData){
        return this.postFile("products/upload", formData).then(response => {
            return response;
        })
    }

    async mapUriToProduct(productId: string, avatarId:string) {
        return this.put("avatar/" +productId, {avatar_id: avatarId}).then(response => {
            return response;
        })
    }
   
}