import {
  Button,
  InputGroup,
  ButtonGroup,
  Form,
  Container,
  Row,
  Col,
} from "react-bootstrap";
import { Services } from "../../services/Services";
import { ChangeEvent, useEffect, useState } from "react";
import { Product } from "../../models/Product";
import { ProductImage, ImageSizes } from "../ProductImage";
import { Card } from "react-bootstrap";
import { ProductLineItem } from "./ProductLineItem";

interface ProductListProps {
  services: Services;
  products: Product[];
}

export const ImmutableProductList: React.FC<ProductListProps> = ({
  products,
  services,
}) => {
  const [numberOfItems, setNumberOfItems] = useState(0);
  useEffect(() => {
    let count = 0;
    products.forEach((product) => (count += product.quantity ?? 1));
    setNumberOfItems(count);
  }, []);
  return (
    <Card>
      <Card.Body>
        <label>Number of Items: {numberOfItems}</label>
        {products.map((product) => {
          return (
            <ProductLineItem
              services={services}
              productId={product.id}
              quantity={product.quantity ?? 1}
            />
          );
        })}
      </Card.Body>
    </Card>
  );
};
