import {
  Material,
  ProductForm,
  Measure,
  CategoryRef,
  Category,
  MaterialRef,
  CategoryRefFlat,
  DispatchDetails,
  Order,
} from "../models/Product";
import axios from "axios";
import { Product } from "../models/Product";
import { ServiceCommon } from "./ServiceCommon";
import { v4 as uuidv4 } from "uuid";

export class ProductService {
  private bearerToken: string = "";
  private serverUrl: string = "";
  constructor(serverRoot: string) {
    this.serverUrl = serverRoot + "api/backstore/";
  }

  public setBearerToken(token: string) {
    this.bearerToken = token;
  }

  private async get(path: string) {
    let config = {
      headers: { Authorization: "bearer " + this.bearerToken },
    };
    return axios.get(this.serverUrl + path, config).then((res) => {
      return res.data;
    });
  }

  private async post(path: string, data: any) {
    let config = {
      headers: { Authorization: "bearer " + this.bearerToken },
    };
    return axios.post(this.serverUrl + path, data, config).then((res) => {
      return res.data;
    });
  }

  private async delete(path: string) {
    let config = {
      headers: { Authorization: "bearer " + this.bearerToken },
    };
    return axios.delete(this.serverUrl + path, config).then((res) => {
      return res.data;
    });
  }

  async createProduct(product: Product) {
    return this.post("product", {
      price_band_id: product.priceBandId,
      details: product.details,
    }).then((product) => {
      return product;
    });
  }

  async getProduct(productId: string) {
    return this.get("product/" + productId).then((product) => {
      return product;
    });
  }

  async deleteProduct(productId: string) {
    return this.delete("product/" + productId).then((product) => {
      return product;
    });
  }

  async getAllProducts(priceBandId: string) {
    return this.get(
      "product/priceband/" + priceBandId + "?salt=" + uuidv4(),
    ).then((products) => {
      return products;
    });
  }

  async dispatchOrder(orderId: string, details: DispatchDetails) {
    return this.post("order/" + orderId + "/dispatch", {
      tracking_id: details.trackingId,
      timestamp: details.timestamp,
      carrier: details.carrier,
    }).then((result) => {
      return result;
    });
  }

  async getOrders(): Promise<Order[]> {
    return this.get("order").then((result) => {
      return result.orders.map((order: any) => {
        return {
          id: order.id,
          status: order.status,
          contact: {
            customerName: order.name,
            addressLine1: order.address_line1,
            addressLine2: order.address_line2,
            pincode: order.pincode,
            phoneNo: order.phoneNo,
          },
          products: order.products.map((product: any) => {
            return {
              id: product.product_id,
              quantity: product.quantity,
              sellerId: product.seller_id,
            };
          }),
        };
      });
    });
  }
}
