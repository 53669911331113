import config from "./config.json"


export interface BackstoreConfig {
    backstore_api_service?: string;
    storecore_api_service?: string;
    images_api_service?: string;
    login_api_service?: string;
}

export class ConfigLoader {
    static load(): BackstoreConfig {
        switch (process.env.NODE_ENV) {
            case "production":
                return config.production;
            default:
                return config.development;
        }
    }
}