import {
  Button,
  InputGroup,
  ButtonGroup,
  Form,
  Container,
  Row,
  Col,
  Card,
} from "react-bootstrap";
import { Services } from "../services/Services";
import { ChangeEvent, useEffect, useState } from "react";
import { Category, CategoryRefFlat, PriceBand } from "../models/Product";
import { ImageUploader } from "./ImageUploader";
import { Product } from "../models/Product";
import { ProductPublish } from "./ProductPublish";

interface CategoryPublishProps {
  services: Services;
  category: CategoryRefFlat;
}

export const CategoryPublish: React.FC<CategoryPublishProps> = ({
  category,
  services,
}) => {
  const [priceBand, setPriceBand] = useState({} as PriceBand);
  const [priceBands, setPriceBands] = useState([] as PriceBand[]);
  const [products, setProducts] = useState([] as Product[]);

  useEffect(() => {
    if (category) {
      services.categoryService.getPriceBands(category).then(async (result) => {
        setPriceBands(result.pricebands);
        const products: Product[] = [];
        for (let priceBand of result.pricebands) {
          await services.productService
            .getAllProducts(priceBand.category_ref.split("/").join(":") + ":" + priceBand.base_price * 1)
            .then((result) => {
              products.push(
                ...result.products.map((product: any) => {
                  return {
                    id: product.id,
                    priceBandId: product.price_band_id,
                    price: priceBand.base_price,
                    details: product.details,
                    productCode: product.product_code,
                  };
                }),
              );
            });
        }
        setProducts(products);
      });
    }
  }, [category]);

  return (
    <Card>
      <Card.Header style={{ backgroundColor: "#ff60f0" }}>
        Product Supply Details
      </Card.Header>
      <Card.Body style={{ backgroundColor: "#FF9AF0" }}>
        {products.map((product) => {
          return <ProductPublish product={product} services={services} />;
        })}
      </Card.Body>
    </Card>
  );
};
