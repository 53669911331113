import { BackstoreConfig } from "../config/ConfigLoader";
import { CategoryService } from "./CategoryService";
import { CoreService } from "./CoreService";
import { ImageService } from "./ImageService";
import LoginService from "./LoginService";
import { ProductService } from "./ProductService";

export interface Services {
    categoryService: CategoryService;
    productService: ProductService;
    imageService: ImageService;
    coreService: CoreService;
    loginService: LoginService;
    setBearerToken(token: string): void;
}

export class JwtServices implements Services {
    categoryService: CategoryService;
    productService: ProductService;
    imageService: ImageService;
    coreService: CoreService;
    loginService: LoginService;

    constructor(config: BackstoreConfig) {
        this.categoryService = new CategoryService(config.storecore_api_service ?? '');
        this.productService = new ProductService(config.backstore_api_service ?? '');
        this.imageService = new ImageService(config.images_api_service ?? '');
        this.coreService = new CoreService(config.storecore_api_service ?? '');
        this.loginService = new LoginService(config.login_api_service ?? '');
    }


    setBearerToken(token: string) {
        this.categoryService.setBearerToken(token);
        this.productService.setBearerToken(token);
        this.imageService.setBearerToken(token);
        this.coreService.setBearerToken(token);
    }
}