import {
  Button,
  InputGroup,
  ButtonGroup,
  Form,
  Container,
  Row,
  Col,
} from "react-bootstrap";
import { Services } from "../../services/Services";
import { ChangeEvent, useEffect, useState } from "react";
import { Product } from "../../models/Product";
import { ProductImage, ImageSizes } from "../ProductImage";
import { Card } from "react-bootstrap";
import { prettyDOM } from "@testing-library/react";

interface ProductLineItemProps {
  services: Services;
  productId: string;
  quantity: number;
}

export const ProductLineItem: React.FC<ProductLineItemProps> = ({
  productId,
  quantity,
  services,
}) => {
  const [product, setProduct] = useState({} as Product);
  useEffect(() => {
    services.productService.getProduct(productId).then((product) => {
      setProduct(product);
    });
  }, [productId]);

  return (
    <Card style={{ maxHeight: 200, overflowY: "auto" }}>
      <Card.Body>
        <div style={{ textAlign: "left" }}>
          <Row>
            <Col>
              <label style={{ fontWeight: "bold" }}>
                {product.details?.title}
              </label>
            </Col>
            <Col>
              <label>{product.productCode}</label>
            </Col>
            <Col>
              {product.details?.imageUris.map((uri) => {
                return (
                  <ProductImage
                    services={services}
                    id={uri}
                    category="products"
                    size={ImageSizes.TINY_SQUARE}
                  />
                );
              })}
            </Col>
            <Col>
              <label>Quantity: {quantity}</label>
            </Col>
          </Row>
        </div>
      </Card.Body>
    </Card>
  );
};
