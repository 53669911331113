
import { Button, InputGroup, ButtonGroup, Form, Container, Row, Col, Card } from "react-bootstrap";
import { Services } from "../services/Services";
import { ChangeEvent, useEffect, useState } from "react";
import { Category, CategoryRefFlat, PriceBand } from "../models/Product";
import { ImageUploader } from "./ImageUploader";
import { Product } from "../models/Product";
import { ProductList } from "./ProductList";

interface ProductDetailsCreateProps {
    services: Services;
    category: CategoryRefFlat
}

export const ProductDetailsCreate: React.FC<ProductDetailsCreateProps> = ({ category, services }) => {
    const [priceBand, setPriceBand] = useState({} as PriceBand);
    const [priceBands, setPriceBands] = useState([] as PriceBand[]);
    const [title, setTitle] = useState('');
    const [description, setDescription] = useState('');
    const [productCode, setProductCode] = useState('');
    const [uploadedImageIds, setUploadedImageIds] = useState<string[]>([]);
    const [products, setProducts] = useState([] as Product[])

    useEffect(() => {
        if (category) {
            services.categoryService.getPriceBands(category).then(async result => {
                setPriceBands(result.pricebands);
                await refreshProducts(result.pricebands);
            })
        }
    }, [category])

    const getPriceOptions = () => {
        return priceBands.map(band => <option value={band.id}>{band.base_price}</option>)
    }

    const setPriceBandFromId = (id: string) => {
        let priceband = priceBands.find(priceBand => priceBand.id == id);
        if (priceband) {
            setPriceBand(priceband);
        }
    }

    const createProduct = () => {
        services.productService.createProduct({ id: 'dummy', priceBandId: priceBand.id, productCode, price: priceBand.base_price, details: { imageUris: uploadedImageIds, title, description } }).then(async result => {
            services.imageService.mapUriToProduct(result.product.id, uploadedImageIds[0]);
            setUploadedImageIds([]);
            await refreshProducts(priceBands);
        });
    }

    const refreshProducts = async (priceBands: any[]) => {
        let products: Product[] = [];
        for (let priceBand of priceBands) {
            await services.productService.getAllProducts(priceBand.id).then(result => {
                products.push(...result.products);
            })
        };
        setProducts(products);
    }

    const deleteProduct = (product: Product) => {
        services.productService.deleteProduct(product.id ?? '').then(async result => {
            await refreshProducts(priceBands);
        });
    }

    return (
        <Card>
            <Card.Header style={{ backgroundColor: "#ff60f0" }}>Create New Product</Card.Header>
            <Card.Body style={{ backgroundColor: "#FF9AF0" }}>
                <Row>
                    <Col sm={8}>
                        <div style={{ marginTop: 2 }}>
                            <ImageUploader services={services} uploadedImageIds={uploadedImageIds} setUploadedImageIds={setUploadedImageIds} />
                        </div>
                        <InputGroup>
                            <InputGroup.Text>Product Title</InputGroup.Text>
                            <Form.Control onChange={event => setTitle(event.target.value)} />
                        </InputGroup>
                        <Form.Select style={{ marginTop: 2 }} onChange={(e) => setPriceBandFromId(e.target.value)}>
                            <option>Base Price</option>
                            {getPriceOptions()}
                        </Form.Select>

                        <InputGroup style={{ marginTop: 2 }}>
                            <InputGroup.Text>Description</InputGroup.Text>
                            <Form.Control onChange={(e) => setDescription(e.target.value)} as="textarea" aria-label="Description" />
                        </InputGroup>
                        <InputGroup style={{ marginTop: 2 }}>
                            <InputGroup.Text>Product Code</InputGroup.Text>
                            <Form.Control onChange={(e) => setProductCode(e.target.value)} as="textarea" aria-label="Product code" />
                        </InputGroup>
                        <ButtonGroup style={{ marginTop: 2 }} aria-label="Create">
                            <Button onClick={createProduct} variant="primary">Create</Button>
                        </ButtonGroup>
                    </Col>
                    <Col sm={4}>
                        <div>
                            <ProductList services={services} products={products} deleteProduct={deleteProduct} />
                        </div>
                    </Col>
                </Row>
            </Card.Body>
        </Card>
    );
};
