import React, { useEffect, useState, useContext, ReactNode } from 'react';
import LoginService from "../services/LoginService";
import { Services } from '../services/Services';
import Cookies from 'js-cookie';
interface LoggedInUser {
    user?: string;
    login?: (username: string, password: string) => void;
    logout?: () => void;
}

const LoggedInUserContext = React.createContext<LoggedInUser>({});

export const LoggedInUserProvider: React.FC<{ children: ReactNode, services: Services }> = ({ children, services }) => {
    const [user, setUser] = useState('');

    useEffect(() => {
        const setToken = async () => {
            const jwtToken = Cookies.get("jwtToken");
            if (jwtToken) {
                try {
                    if (services.loginService) {
                        await services.loginService.verifyToken(jwtToken as string).then(payload => {
                            services.setBearerToken(jwtToken as string);
                            setUser(payload.handle);
                        })
                    }
                } catch (e) {
                    console.log(e);
                    Cookies.remove("jwtToken");
                    setUser('');
                }
            }
        }
        setToken();
    }, [services])


    const login = (username: string, password: string) => {
        services.loginService.loginUser(username, password).then(token => {
            services.loginService.verifyToken(token.token).then((payload: any) => {
                Cookies.set("jwtToken", token.token, { SameSite: "None;", secure: true });
                services.setBearerToken(token.token);
                setUser(payload.handle);
            });
        }, err => {
            console.log(err);
        });
    }

    const logout = () => {
        Cookies.remove("jwtToken");
        setUser('');
    }
    return <LoggedInUserContext.Provider value={{ user, login, logout }}>{children}</LoggedInUserContext.Provider>

}

export const useLogin = () => {
    return useContext(LoggedInUserContext);
};