import React, { useEffect, useState, useContext, ReactNode } from 'react';
import { ConfigLoader, BackstoreConfig } from '../config/ConfigLoader';
import { JwtServices, Services } from '../services/Services';
import { useConfig } from './UseConfig';


const ServicesContext = React.createContext<Services>({} as Services);

export const ServicesProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
    const config = useConfig();
    const [services, setServices] = useState({} as Services);

    useEffect(() => {
        setServices(new JwtServices(config));
    }, [config])

    return <ServicesContext.Provider value={services}>{children}</ServicesContext.Provider>

}

export const useServices = () => {
    return useContext(ServicesContext);
};