import { PropsWithChildren } from "react";
import { TitleBar } from "./TitleBar";
import { ActionsBar } from "./ActionsBar";

interface HeaderBarProps extends PropsWithChildren {
}

export const HeaderBar: React.FC<HeaderBarProps> = ({ children }) => {
    return <TitleBar>
        <ActionsBar />
    </TitleBar>
};

