import {
  Button,
  InputGroup,
  ButtonGroup,
  Form,
  Container,
  Row,
  Col,
} from "react-bootstrap";
import { Services } from "../services/Services";
import { ChangeEvent, useEffect, useState } from "react";
import { Product } from "../models/Product";
import { ProductImage } from "./ProductImage";
import { ImageSizes } from "./ProductImage";
import { Card } from "react-bootstrap";

interface ProductPublishProps {
  services: Services;
  product: Product;
}

export const ProductPublish: React.FC<ProductPublishProps> = ({
  product,
  services,
}) => {
  const [supplyPerDay, setSupplyPerDay] = useState(20);
  const publishProduct = (product: Product) => {
    const { productCode, priceBandId, price } = product;
    services.coreService
      .offerProduct({
        productId: product.id,
        productCode: product.id,
        priceBandId,
        price,
        sellerId: "vandi.world",
        startDate: new Date(),
        supplyPerDay,
      })
      .then((offer) => {
        if (offer.accepted_offer.id) {
          services.coreService.updateProductDetails(
            offer.accepted_offer.id,
            product.details,
          );
        }
      });
  };

  return (
    <div style={{ textAlign: "left" }}>
      <label style={{ fontWeight: "bold" }}>{product.details?.title}</label>
      <br />
      <label>{product.details?.description}</label>
      <br />
      <label>Code: {product.productCode}</label>
      <br />
      {product.details?.imageUris.map((uri) => {
        return (
          <ProductImage
            services={services}
            id={uri}
            category="products"
            size={ImageSizes.TINY_SQUARE}
          />
        );
      })}
      <Row>
        <Col>
          <label>Maximum Supply Price: {product.price}</label>
        </Col>
        <Col>
          <InputGroup style={{ marginTop: 2 }}>
            <InputGroup.Text>Supply Per Day</InputGroup.Text>
            <Form.Control
              value={supplyPerDay}
              onChange={(e) => setSupplyPerDay(+e.target.value)}
              aria-label="Supply Per Day"
            />
          </InputGroup>
        </Col>
        <Col>
          <Button onClick={() => publishProduct(product)}>Publish</Button>
        </Col>
      </Row>
      <hr />
    </div>
  );
};
