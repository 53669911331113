import {
  Button,
  InputGroup,
  ButtonGroup,
  Form,
  Container,
  Row,
  Col,
} from "react-bootstrap";
import { Services } from "../../services/Services";
import { ChangeEvent, useEffect, useState } from "react";
import { Card } from "react-bootstrap";
import { DispatchDetails, Order as OrderModel } from "../../models/Product";

interface OrderProps {
  services: Services;
  order: OrderModel;
}

export const DispatchedOrder: React.FC<OrderProps> = ({ services, order }) => {
  return (
    <Card key={order.id}>
      <Card.Body>
        <label>Customer: {order.contact.customerName}</label>
        <label>AddressLine1: {order.contact.addressLine1}</label>
        <label>AddressLine2: {order.contact.addressLine2}</label>
        <label>Pincode: {order.contact.pincode}</label>
        <label>PhoneNo: {order.contact.phoneNo}</label>
        <div>
          {order.products.map((product) => {
            return (
              <div key={product.id}>
                <label>Product Id: {product.id}</label>
                <label>Quantity: {product.quantity}</label>
              </div>
            );
          })}
        </div>
      </Card.Body>
    </Card>
  );
};
