import React, { useState, PropsWithChildren } from 'react'
import { Services } from '../services/Services';
import { Container, Image } from 'react-bootstrap';
import { useConfig } from '../hooks/UseConfig';

export enum ImageSizes {
    TINY_SQUARE,
    SMALL_SQUARE,
    MEDIUM_SQUARE,
    SMALL_RECTANGLE,
    LARGE_RECTANGLE,
}

interface ProductImageProps {
    services: Services;
    category: string;
    id: string;
    size: ImageSizes;
}

export const ProductImage: React.FC<ProductImageProps> = ({ services, category, id, size }) => {
    const config = useConfig();
    const serverUrl = config.images_api_service + "api/avatars/"
    let avatarUrl = category + "/"

    avatarUrl = serverUrl + avatarUrl + id;
    let width = 64.0;
    let height = 64.0;
    switch (size) {
        case ImageSizes.TINY_SQUARE:
            width = width / 1.618;
            height = height / 1.618
            break;
        case ImageSizes.SMALL_SQUARE:
            width = width * 1.618;
            height = height * 1.618;
            break;
        case ImageSizes.SMALL_RECTANGLE:
            width = width * 1.618 * 2;
            height = height * 1.618;
            break;
        case ImageSizes.MEDIUM_SQUARE:
            width = width * 1.618 * 1.618;
            height = height * 1.618 * 1.618 * 1.618;
            break;
        case ImageSizes.LARGE_RECTANGLE:
            width = width * 1.618 * 1.618 * 1.618 * 1.618;
            height = height * 1.618 * 1.618 ;
            break;
    }


    return (
        <Image style={{ padding: 2, height, width, borderColor: 'grey', borderWidth: 1 }} src={avatarUrl} />        )
}