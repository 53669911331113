import {
  Button,
  InputGroup,
  ButtonGroup,
  Form,
  Container,
  Row,
  Col,
} from "react-bootstrap";
import { Services } from "../../services/Services";
import { ChangeEvent, useEffect, useState } from "react";
import { Card } from "react-bootstrap";
import { DispatchDetails, Order as OrderModel } from "../../models/Product";
import { Order } from "./Order";

interface NewOrdersListProps {
  services: Services;
  orders: OrderModel[];
  dispatchOrder: (orderId: string, details: DispatchDetails) => void;
}

export const NewOrdersList: React.FC<NewOrdersListProps> = ({
  services,
  orders,
  dispatchOrder,
}) => {
  return (
    <>
      {orders.map((order) => {
        return (
          <Order
            services={services}
            order={order}
            dispatchOrder={dispatchOrder}
          />
        );
      })}
    </>
  );
};
