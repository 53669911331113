import { Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

interface ActionsBarProps {}
export const ActionsBar: React.FC<ActionsBarProps> = () => {
  const navigate = useNavigate();

  const toAddProduct = () => {
    navigate("/admin/product/add");
  };

  const toPublishProduct = () => {
    navigate("/admin/product/publish");
  };

  const toOrders = () => {
    navigate("/admin/orders");
  };

  return (
    <div>
      <Button style={{ marginRight: 10 }} onClick={toOrders}>
        Orders
      </Button>
      <Button style={{ marginRight: 10 }} onClick={toAddProduct}>
        Inventory
      </Button>
      <Button onClick={toPublishProduct}>Supply</Button>
    </div>
  );
};
