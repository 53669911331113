import './App.css';
import { useServices } from './hooks/UseServices';
import { Home } from './screens/Home';


function App() {
  const services = useServices();
  console.log("Services: ", services);
  return (
    <div className="App">
      <Home services={services}/>
    </div>
  );
}

export default App;
