import { CategoryRef, Category, MaterialRef, CategoryRefFlat } from "../models/Product";
import axios from "axios";
import {ServiceCommon} from "./ServiceCommon"

export class CategoryService {
    private bearerToken: string = '';
    private serverUrl: string;

    constructor(serverRoot: string) {
        this.serverUrl = serverRoot + "api/storecore/"
    }

    public setBearerToken(token: string) {
        this.bearerToken = token;
    }

    private async get(path: string) {
        if(this.bearerToken === ''){
            return Promise.resolve({});
        }

        let config = {
            headers: { 'Authorization': "bearer " + this.bearerToken }
        };
        return axios.get(this.serverUrl + path, config).then(res => {
            return res.data;
        })
    }

    private async post(path: string, data: any) {
        let config = {
            headers: { 'Authorization': "bearer " + this.bearerToken }
        };
        return axios.post(this.serverUrl + path, data, config).then(res => {
            return res.data;
        })
    }


    async getMaterialsRaw() {
        return this.get("material").then(materials => {
            return materials;
        });
    }

    async getFormsRaw() {
        return this.get("form").then(forms => {
            return forms;
        });
    }

    async getMeasuresRaw() {
        return this.get("measure").then(measures => {
            return measures;
        });
    }

    async createMaterial(material: string) {
        return this.post("material", { material }).then(material => {
            return material;
        });
    }

    async createForm(form: string) {
        return this.post("form", { form }).then(form => {
            return form;
        });
    }

    async createMeasure(measure: string) {
        return this.post("measure", { measure }).then(measure => {
            return measure;
        });
    }

    async createCategoryRef(material: string, form: string, measure: string) {
        return this.post("category", { material, form, measure }).then(category => {
            return category;
        });
    }

    async getMaterials() {
        return this.get("category/material").then(materials => {
            return materials;
        });
    }

    async getForms(material: string) {
        return this.get("category/material/" + material + "/form").then(forms => {
            return forms;
        });
    }

    async getMeasures(material: string, form: string) {
        return this.get("category/material/" + material + "/form/" + form + "/measure").then(measures => {
            return measures;
        });
    }

    async addPriceBand(categoryRef: CategoryRefFlat, basePrice: number){
        return this.post("category/" + ServiceCommon.refToUri(categoryRef) + "/priceband", {base_price: basePrice}).then(pricebands =>{
            return pricebands;
        });
    }

    async getPriceBands(categoryRef: CategoryRefFlat){
        return this.get("category/" + ServiceCommon.refToUri(categoryRef) + "/priceband").then(pricebands =>{
            return pricebands;
        });
    }
    getCategory(ref: CategoryRefFlat): CategoryRefFlat {
        return ref;
    }

    async getAllCategories() {
        return this.get("category").then(categories => {
            return categories;
        })
    }

    createCategory(ref: CategoryRef): Category {
        return { ref, price: 105 }
    }
}