import {
  Material,
  ProductForm,
  Measure,
  CategoryRef,
  Category,
  MaterialRef,
  CategoryRefFlat,
  ProductDetails,
} from "../models/Product";
import axios from "axios";
import { Product, ProductOffer, Order } from "../models/Product";

import { ServiceCommon } from "./ServiceCommon";

export class CoreService {
  private bearerToken: string = "";
  private serverUrl: string = "";
  constructor(serverRoot: string) {
    this.serverUrl = serverRoot + "api/storecore/";
  }

  public setBearerToken(token: string) {
    this.bearerToken = token;
  }

  private async get(path: string) {
    let config = {
      headers: { Authorization: "bearer " + this.bearerToken },
    };
    return axios.get(this.serverUrl + path, config).then((res) => {
      return res.data;
    });
  }

  private async post(path: string, data: any) {
    let config = {
      headers: { Authorization: "bearer " + this.bearerToken },
    };
    return axios.post(this.serverUrl + path, data, config).then((res) => {
      return res.data;
    });
  }

  private async put(path: string, data: any) {
    let config = {
      headers: { Authorization: "bearer " + this.bearerToken },
    };
    return axios.put(this.serverUrl + path, data, config).then((res) => {
      return res.data;
    });
  }

  private async delete(path: string) {
    let config = {
      headers: { Authorization: "bearer " + this.bearerToken },
    };
    return axios.delete(this.serverUrl + path, config).then((res) => {
      return res.data;
    });
  }

  async offerProduct(offer: ProductOffer) {
    return this.post("offer", {
      offer: {
        product_id: offer.productId,
        price_band_id: offer.priceBandId,
        price: offer.price,
        supply_per_day: offer.supplyPerDay,
        product_code: offer.productCode,
        start_date: offer.startDate,
        seller_id: offer.sellerId,
      },
    }).then((product) => {
      return product;
    });
  }

  async updateProductDetails(offerId: string, details: ProductDetails) {
    return this.put("offer/" + offerId + "/details", { details }).then(
      (result) => {
        return result;
      },
    );
  }
  async publishProduct(product: Product) {
    return this.post("product/publish", product).then((product) => {
      return product;
    });
  }

  async getMaterials() {
    return this.get("category/material").then((materials) => {
      return materials;
    });
  }

  async getForms(material: string) {
    return this.get("category/material/" + material + "/form").then((forms) => {
      return forms;
    });
  }

  async getMeasures(material: string, form: string) {
    return this.get(
      "category/material/" + material + "/form/" + form + "/measure",
    ).then((measures) => {
      return measures;
    });
  }

  async getPriceBands(categoryRef: CategoryRefFlat) {
    return this.get(
      "category/" + ServiceCommon.refToUri(categoryRef) + "/priceband",
    ).then((pricebands) => {
      return pricebands;
    });
  }

  async getAllCategories() {
    return this.get("category").then((categories) => {
      return categories;
    });
  }
}
