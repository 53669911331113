import { useState, useEffect } from "react";
import Form from "react-bootstrap/Form";
import {
    Container,
    Row,
    Col,
    Card,
    Dropdown,
    ButtonGroup,
    Button,
    InputGroup,
    ListGroup,
    Table,
} from "react-bootstrap";
import { Services } from "../services/Services";
import {
    Category,
    MaterialRef,
    ProductFormRef,
    MeasureRef,
    CategoryRefFlat,
} from "../models/Product";
import { CategoryDetailsView } from "./CategoryDetailsView";
import { DimensionSelect } from "./DimensionSelect";
import { PriceBandCreate } from "./PriceBandCreate";

interface CategoryListProps {
    services: Services;
    categories: CategoryRefFlat[];
    renderCategory: (category: CategoryRefFlat) => React.JSX.Element;
}

export const CategoryList: React.FC<CategoryListProps> = ({
    services,
    categories,
    renderCategory,
}) => {
    return (
        <Table>
            <thead>
                <tr>
                    <th>Material</th>
                    <th>Form</th>
                    <th>Measure</th>
                    <th>Action</th>
                </tr>
            </thead>
            <tbody>
                {categories.map((category) => {
                    return (
                        <tr>
                            <td>{category.material}</td>
                            <td>{category.form}</td>
                            <td>{category.measure}</td>
                            <td>{renderCategory(category)}</td>
                        </tr>
                    );
                })}
            </tbody>
        </Table>
    );
};
