
import { Button, InputGroup, ButtonGroup, Form, Container, Row, Col } from "react-bootstrap";
import { Services } from "../services/Services";
import { ChangeEvent, useEffect, useState } from "react";
import { ImageSizes, ProductImage } from "./ProductImage";

interface ImageUploaderProps {
    services: Services;
    setUploadedImageIds: (imageIds: string[]) => void;
    uploadedImageIds: string[];
}

export const ImageUploader: React.FC<ImageUploaderProps> = ({ services, setUploadedImageIds, uploadedImageIds }) => {
    const [imageFile, setImageFile] = useState<File | null>(null);

    const uploadImage = () => {
        const formData = new FormData();
        if (imageFile != null) {
            formData.append('product_image', imageFile);
        }
        services.imageService.uploadImage(formData).then(result => {
             let imageIds = [...uploadedImageIds, result.avatar.id]
            setUploadedImageIds(imageIds);
        })
    }

    const setFile = (e: ChangeEvent) => {
        const files = (e.target as HTMLInputElement).files;
        if (files != null) {
            setImageFile(files[0]);
        }
    }

    return (
        <div>
            <InputGroup>
                <Form.Control
                    type="file"
                    onChange={setFile}
                    name="product_image"
                />
                <Button variant="primary" id="button-addon2" onClick={uploadImage}>
                    Upload Image
                </Button>
            </InputGroup>
            <Row style={{margin:"inherit"}}>
            {uploadedImageIds.map(id => 
                <ProductImage services={services} id={id} size={ImageSizes.SMALL_SQUARE} category="products"></ProductImage>
            )}
            </Row>
        </div>
    );
};
