import {
  Button,
  InputGroup,
  ButtonGroup,
  Form,
  Container,
  Row,
  Col,
} from "react-bootstrap";
import { Services } from "../../services/Services";
import { ChangeEvent, useEffect, useState } from "react";
import { Card } from "react-bootstrap";
import { DispatchDetails, Order as OrderModel } from "../../models/Product";
import { ProductList } from "../ProductList";
import { ImmutableProductList } from "./ImmutableProductList";

interface OrderProps {
  services: Services;
  order: OrderModel;
  dispatchOrder: (orderId: string, details: DispatchDetails) => void;
}

export const Order: React.FC<OrderProps> = ({
  services,
  order,
  dispatchOrder,
}) => {
  const [trackingId, setTrackingId] = useState("");

  const dispatch = () => {
    dispatchOrder(order.id, {
      trackingId,
      carrier: "PC",
      timestamp: new Date(),
    });
  };
  return (
    <Card key={order.id}>
      <Card.Body>
        <Row>
          <Col>
            <div style={{ textAlign: "left" }}>
              <label>
                Customer:{" "}
                <span style={{ fontWeight: "bold" }}>
                  {order.contact.customerName}
                </span>
              </label>
              <br />
              <label>AddressLine1: {order.contact.addressLine1}</label>
              <br />
              <label>AddressLine2: {order.contact.addressLine2}</label>
              <br />
              <label>Pincode: {order.contact.pincode}</label>
              <br />
              <label>PhoneNo: {order.contact.phoneNo}</label>
            </div>
          </Col>
          <Col xs={4}>
            <ImmutableProductList
              services={services}
              products={order.products}
            />
          </Col>
          <Col>
            <div style={{ textAlign: "left" }}>
              <InputGroup style={{ marginTop: 2 }}>
                <InputGroup.Text>Tracking Id</InputGroup.Text>
                <Form.Control
                  value={trackingId}
                  onChange={(e) => setTrackingId(e.target.value)}
                  aria-label="Tracking id"
                />
              </InputGroup>
              <Button onClick={() => dispatch()}>Dispatch</Button>
            </div>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};
