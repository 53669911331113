import { Services } from "../services/Services";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { AddProduct } from "./AddProduct";
import { SignIn } from "./SignIn";
import { AdminHome } from "./AdminHome";
import { LoggedInUserProvider } from "../hooks/UseLogin";
import { PublishProduct } from "./PublishProduct";
import { OrdersScreen } from "./OrdersScreen";

interface HomeProps {
  services: Services;
}
export const Home: React.FC<HomeProps> = ({ services }) => {
  return (
    <BrowserRouter>
      <LoggedInUserProvider services={services}>
        <Routes>
          <Route path="/" element={<SignIn services={services} />} />
          <Route
            path="/admin/home"
            element={<AdminHome services={services} />}
          />
          <Route
            path="/admin/orders"
            element={<OrdersScreen services={services} />}
          />
          <Route
            path="/admin/product/add"
            element={<AddProduct services={services} />}
          />
          <Route
            path="/admin/product/publish"
            element={<PublishProduct services={services} />}
          />
        </Routes>
      </LoggedInUserProvider>
    </BrowserRouter>
  );
};
