import {
  Button,
  InputGroup,
  ButtonGroup,
  Form,
  Container,
  Row,
  Col,
} from "react-bootstrap";
import { Services } from "../../services/Services";
import { ChangeEvent, useEffect, useState } from "react";
import { Card } from "react-bootstrap";
import { Order as OrderModel } from "../../models/Product";
import { Order } from "./Order";
import { DispatchedOrder } from "./DispatchedOrder";

interface DispatchedOrdersListProps {
  services: Services;
  orders: OrderModel[];
}

export const DispatchedOrdersList: React.FC<DispatchedOrdersListProps> = ({
  services,
  orders,
}) => {
  return (
    <>
      {orders.map((order) => {
        return <DispatchedOrder services={services} order={order} />;
      })}
    </>
  );
};
