import React, { useEffect, useState } from 'react'
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import { HeaderBar } from '../vandi/HeaderBar';
import { Services } from '../services/Services';
import { useNavigate } from 'react-router-dom';
import { useLogin } from '../hooks/UseLogin';

export interface SignInProps {
    services: Services;
}

export const SignIn: React.FC<SignInProps> = ({ services }) => {
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const navigate = useNavigate();

    const {user, login} = useLogin();

    useEffect(() => {
        if(user != ''){
           navigate("/admin/home");
        }
    }, [user])

    const handleLogin = () => {
       if(login){
        login(username, password);
        navigate("/");
       }
    };

    return (
        <Container fluid className='p-0'>
            <HeaderBar />
            <div style={{ padding: "2vw", backgroundImage: "linear-gradient(to right, #ff10f0, #ff9Af0)" }}>
                <Row>
                    <Col md={6}>
                    </Col>
                    <Col md={5}>
                        <div style={{ padding: "2vw", backgroundColor: "white", borderRadius: 5, minHeight: "70vh" }}>
                            <label className='display-6'>Sign In</label>
                            <br></br>
                            <br />
                            <Form.Group controlId="formBasicEmail">
                                <Form.Label style={{ fontSize: "small" }}>Login Id</Form.Label>
                                <Form.Control style={{ fontSize: "small" }} onChange={(e) => setUsername(e.target.value)} type="input" placeholder="Enter login id" />
                            </Form.Group>

                            <Form.Group controlId="formBasicPassword">
                                <Form.Label style={{ fontSize: "small" }} >Password</Form.Label>
                                <Form.Control style={{ fontSize: "small" }} onChange={(e) => setPassword(e.target.value)} type="password" placeholder="Password" />
                            </Form.Group>
                            <div style={{ paddingTop: 10 }}>
                                <Button onClick={() => handleLogin()} className="mr-2" variant="primary" type="submit">
                                    Login
                                </Button>
                            </div>
                        </div>
                    </Col>
                    <Col md={1}>
                    </Col>
                </Row>
            </div>
        </Container>
    )
}