import { HeaderBar } from "../vandi/HeaderBar";
import { Services } from "../services/Services";
import { styles } from "../styles/default";
import { NewOrdersList } from "../components/orders/NewOrdersList";
import { Tabs, Tab } from "react-bootstrap";
import { DispatchedOrdersList } from "../components/orders/DispatchedOrdersList";
import { useState, useEffect } from "react";
import { Order } from "../models/Product";
import { DispatchDetails } from "../models/Product";

interface OrdersScreenProps {
  services: Services;
}
export const OrdersScreen: React.FC<OrdersScreenProps> = ({ services }) => {
  const [dispatchedOrders, setDispatchedOrders] = useState([] as Order[]);
  const [newOrders, setNewOrders] = useState([] as Order[]);

  const dispatchOrder = (orderId: string, details: DispatchDetails) => {
    services.productService.dispatchOrder(orderId, details).then((status) => {
      refresh();
    });
  };

  useEffect(() => {
    refresh();
  }, []);

  const refresh = () => {
    services.productService.getOrders().then((orders) => {
      console.log(orders);
      setDispatchedOrders(
        orders.filter((order) => order.status === "DISPATCHED"),
      );
      setNewOrders(orders.filter((order) => order.status !== "DISPATCHED"));
    });
  };

  return (
    <div
      style={{ backgroundColor: styles.bodyColor, flex: 1, height: "100vh" }}
    >
      <HeaderBar />
      <div style={{ padding: "1rem" }}>
        <Tabs>
          <Tab eventKey="newOrders" title="New Orders">
            <NewOrdersList
              services={services}
              orders={newOrders}
              dispatchOrder={dispatchOrder}
            />
          </Tab>
          <Tab eventKey="dispatchedOrders" title="Dispatched Orders">
            <DispatchedOrdersList
              services={services}
              orders={dispatchedOrders}
            />
          </Tab>
        </Tabs>
      </div>
    </div>
  );
};
