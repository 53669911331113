import { HeaderBar } from "../vandi/HeaderBar";
import { Services } from "../services/Services";
import { ProductDetailsCreate } from "../components/ProductDetailsCreate";
import { styles } from "../styles/default";
import { CategoryList } from "../components/CategoryList";
import { Row,Col, Container } from "react-bootstrap";
import { useEffect, useState } from "react";
import { CategoryRefFlat } from "../models/Product";
import { CategoryPublish } from "../components/CategoryPublish";

interface PublishProductProps {
    services: Services;
}
export const PublishProduct: React.FC<PublishProductProps> = ({services}) => {
    const [categories, setCategories] = useState([] as CategoryRefFlat[]);

    useEffect(() => {
        if (services.categoryService) {
            services.categoryService.getAllCategories().then(result => {
                if (result.categories) {
                    setCategories(result.categories);
                }
            })
        }
    }, []);
    
    return (
        <div style={{backgroundColor: styles.bodyColor}}>
            <HeaderBar/>
            <Container>
                <Row>
                    <Col sm={1}></Col>
                    <Col sm={10}>
                        <CategoryList services={services} categories={categories} renderCategory={(category) => <CategoryPublish category={category} services={services} />}></CategoryList>
                    </Col>
                    <Col sm={1}></Col>
                </Row>
            </Container>
        </div>
    );
};

