import { HeaderBar } from "../vandi/HeaderBar";
import { CategorySelect } from "../components/CategorySelect";
import { Services } from "../services/Services";
import { ProductDetailsCreate } from "../components/ProductDetailsCreate";
import { useState, useEffect } from "react";
import { Category, CategoryRefFlat } from "../models/Product";
import { CategoryList } from "../components/CategoryList";
import { Container, Row, Col } from "react-bootstrap";
import { styles } from "../styles/default";

interface AddProductProps {
    services: Services;
}
export const AddProduct: React.FC<AddProductProps> = ({ services }) => {
    const [categories, setCategories] = useState([] as CategoryRefFlat[]);

    useEffect(() => {
        if (services.categoryService) {
            services.categoryService.getAllCategories().then(result => {
                if (result.categories) {
                    setCategories(result.categories);
                }
            })
        }
    }, []);

    return (
        <div style={{ backgroundColor: styles.bodyColor }}>
            <HeaderBar />
            <Container>
                <Row>
                    <Col sm={1}></Col>
                    <Col sm={10}>
                        <CategoryList services={services} categories={categories} renderCategory={(category) => <ProductDetailsCreate category={category} services={services} />}></CategoryList>
                    </Col>
                    <Col sm={1}></Col>
                </Row>
            </Container>
        </div>
    );
};

