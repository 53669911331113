import {
  Button,
  InputGroup,
  ButtonGroup,
  Form,
  Container,
  Row,
  Col,
} from "react-bootstrap";
import { Services } from "../services/Services";
import { ChangeEvent, useEffect, useState } from "react";
import { Product } from "../models/Product";
import { ProductImage } from "./ProductImage";
import { ImageSizes } from "./ProductImage";
import { Card } from "react-bootstrap";

interface ProductListProps {
  services: Services;
  products: Product[];
  deleteProduct: (product: Product) => void;
}

export const ProductList: React.FC<ProductListProps> = ({
  products,
  services,
  deleteProduct,
}) => {
  return (
    <Card style={{ maxHeight: 200, overflowY: "auto" }}>
      <Card.Body>
        {products.map((product) => {
          return (
            <div style={{ textAlign: "left" }}>
              <label style={{ fontWeight: "bold" }}>
                {product.details.title}
              </label>
              <br />
              {product.details.imageUris.map((uri) => {
                return (
                  <ProductImage
                    services={services}
                    id={uri}
                    category="products"
                    size={ImageSizes.TINY_SQUARE}
                  />
                );
              })}
              <Button onClick={() => deleteProduct(product)}>Delete</Button>
              <hr />
            </div>
          );
        })}
      </Card.Body>
    </Card>
  );
};
